:root {
  --error: #e7195a;
}
.form-and-preview-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .form-and-preview-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.create-job {
  background: rgba(93, 169, 233, 0.95);
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 10px 10px 5px lightgrey;
}

.create-job label {
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
}

.create-job input[type="text"],
.create-job textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: horizontal none;
}

.job-type-form {
  background: #3967bc;
  padding: 10px;
  border-radius: inherit;
}

.check-option {
  margin-bottom: 5px;
}

.check-option input[type="checkbox"] {
  margin-right: 10px;
}

.create-job input[type="checkbox"] {
  margin-top: 10px;
}

.create-job button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #5682d2;
  color: #fcfcfc;
  border: clear;
  border-radius: 4px;
  cursor: pointer;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
}

.create-job button:hover {
  background-color: #4565a0;
}
