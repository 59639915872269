/*
=============== 
Global Styles
===============
*/

* {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  justify-content: center;
}

/*
=============== 
Hero
===============
*/

.hero {
  min-height: 100vh;
  background: var(--clr-primary-5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-banner {
  text-align: center;
  color: var(--clr-white);
  padding: 0 3rem;
}
.hero-banner h1 {
  text-transform: uppercase;
}
.hero-banner p {
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--clr-white);
  letter-spacing: var(--spacing);
}
.hero-btn {
  padding: 0.9rem 1.6rem;
  font-size: 1.25rem;
  background-color: aqua;
}
.hero-btn:hover {
  background: transparent;
  color: var(--clr-white);
  border-color: var(--clr-white);
}
@media screen and (max-width: 767px) {
  .hero {
    background: url("./images/heroPhoto.jpg") center/cover no-repeat;
}
.hero-banner {
  border-radius: 13px;
  padding: 15px;
  color: bisque;
  background-color: rgba(255, 255, 255, 0.33);
}
.hero-banner p {
  color: bisque;
  max-width: 45rem;
}}
@media screen and (min-width: 768px) {
  .hero {
    background: url("./images/heroPhoto.jpg") center/cover no-repeat;
  }

  .hero-banner {
    border-radius: 13px;
    padding: 15px;
    color: bisque;
    background-color: rgba(255, 255, 255, 0.33);
  }
  .hero-banner p {
    color: bisque;
    max-width: 45rem;
  }
}
